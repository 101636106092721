import * as React from "react";
import Image from "next/image";
import styles from "./masonry.module.scss";
import { getAlbumDetails } from "@src/@types/api.d";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import Dialog from "src/components/Dialog";
import Icon from "../Icon";
import Button from "../Button";
import Link from "next/link";

type Props = {
  eventId: number;
  albumDetailsData: getAlbumDetails;
  eventSlug: string;
};

export function insertAt(
  array: unknown[],
  index: number,
  ...elementsArray: unknown[]
) {
  array.splice(index, 0, ...elementsArray);
}

const MasonryComp: React.FC<Props> = ({
  eventId,
  eventSlug,
  albumDetailsData,
}) => {
  return !!albumDetailsData?.getMedia &&
    albumDetailsData?.getMedia?.length > 0 ? (
    <div className={styles["home__gallery-section"]}>
      <div className={styles["home__gallery-section-container"]}>
        <div className={styles["home__gallery-section-container-inner"]}>
          {!!albumDetailsData?.getMedia &&
            albumDetailsData?.getMedia?.slice(0, 2)?.map((image, index) => {
              if (image && image.meta?.s3_url) {
                return (
                  <Dialog
                    key={`photos-home-${index}`}
                    trigger={
                      <div className={styles["home__gallery-section-image"]}>
                        <Image
                          placeholder="blur"
                          blurDataURL={`data:image/svg+xml;base64,${toBase64(
                            shimmer(700, 475)
                          )}`}
                          src={image.meta.s3_url}
                          layout="fill"
                          alt="gallery"
                          objectFit="contain"
                        />
                      </div>
                    }
                  >
                    <div className={styles["home__carousel"]}>
                      <div className={styles["home__carousel-image"]}>
                        <div
                          key={`photos-home-${index}`}
                          className={
                            styles["home__gallery-section-image-outside"]
                          }
                        >
                          {/* <a
                              target="_blank"
                              rel="noreferrer noopenner"
                              href={`${downloadLink}`}
                              download
                              className={
                                styles[
                                  "home__gallery-section-image-big__download"
                                ]
                              }
                            >
                              <Icon iconName="download2" />
                            </a> */}
                          <div
                            key={`photos-home-${index}`}
                            className={
                              styles["home__gallery-section-image-big"]
                            }
                          >
                            <Image
                              placeholder="blur"
                              blurDataURL={`data:image/svg+xml;base64,${toBase64(
                                shimmer(700, 475)
                              )}`}
                              src={image.meta.s3_url}
                              layout="fill"
                              alt="gallery"
                              objectFit="contain"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                );
              }
            })}
        </div>
        <div className={styles["home__gallery-section-container-button"]}>
          <Link
            href={`https://${eventSlug}.${process.env.NEXT_PUBLIC_INAWO_ALBUM_SITE_END}`}
            passHref
          >
            <a target="_blank">
              <Button design="other" type="button" label="View More">
                See full photobook
              </Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MasonryComp;
