import { gql } from "@apollo/client";

export const SignUpByInviteLink = gql`
  mutation signupByInviteLink($eventId: Int!, $inviteToken: String!) {
    signupByInviteLink(eventId: $eventId, inviteToken: $inviteToken) {
      access_expiry
      access_token
      refresh_token
      refresh_expiry
      user {
        first_name
        last_name
        id
        phone_number
        verified
        email
      }
    }
  }
`;

export const CreateMessage = gql`
  mutation createMessage($eventId: Int!, $content: String!) {
    createMessage(event_id: $eventId, content: $content)
  }
`;

export const UpdateRsvpStatus = gql`
  mutation updateRsvpStatus($eventId: Int!, $status: String!) {
    updateRsvpStatus(event_id: $eventId, status: $status)
  }
`;

export const PayForCashGiftV1 = gql`
  mutation payForCashGiftV1($payload: payForCashGiftRequest!) {
    payForCashGiftV1(payload: $payload)
  }
`;

export const PayForWishListItemV1 = gql`
  mutation payForWishListItemV1($payload: payForWishlistItemRequest!) {
    payForWishListItemV1(payload: $payload)
  }
`;

export const PayForSprayMoneyV1 = gql`
  mutation payForSprayMoneyV1($payload: payForSprayMoneyRequest!) {
    payForSprayMoneyV1(payload: $payload)
  }
`;
